import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog, { DialogProps } from '@mui/material/Dialog';

type ConfirmDialogProps = Omit<DialogProps, 'title' | 'content'> & {
  title: React.ReactNode;
  content?: React.ReactNode;
  onClose: any;
  onClickConfirm?: VoidFunction;
  onClickCancel?: VoidFunction;
  isLoading?: boolean;
};

export default function ConfirmDialog({
  title,
  content,
  open,
  onClose,
  onClickConfirm,
  onClickCancel,
  isLoading,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 1, textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent sx={{ pb: 2, typography: 'body2', textAlign: "center" }}>{content}</DialogContent>
      <DialogActions>
        <LoadingButton loading={isLoading} fullWidth variant="contained" color="primary" onClick={onClickConfirm}>
          Confirmar
        </LoadingButton>
        <LoadingButton loading={isLoading || false} fullWidth variant="outlined" color="inherit" onClick={onClickCancel}>
          Cancelar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
