import { useLocation } from 'react-router';
import { useState, useEffect } from 'react';

import { paths } from 'src/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

export const useTabs = (permission: string) => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const responsive = useResponsive;

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setValue(0);
        break;
      case paths.orders.root:
        setValue(0);
        break;
      case paths.kanban.root:
        setValue(1);
        break;
      case paths.users.root:
        setValue(2);
        break;
      case paths.insurance.root:
        setValue(3);
        break;
      // case paths.clients.root:
      //   setValue(4);
      //   break;
      case paths.installers.root:
        if (permission === 'Lojista') {
          setValue(1);
        } else {
          setValue(4);
        }
        break;
      case paths.installers.details(
        location.pathname.match(/installers\/details\/(\d+)$/)?.[1] || ''
      ):
        setValue(4);
        break;
      case paths.cycles.root:
        setValue(5);
        break;
      case paths.insuranceCycle.root:
        setValue(6);
        break;
      case paths.settings.root:
        setValue(7);
        break;
      case paths.notifications.root:
        setValue(8);
        break;
      default:
        setValue(0);
        break;
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabsHook: any = {
    value,
    handleChange,
    responsive,
  };

  return TabsHook;
};
