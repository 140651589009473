export const requisitionDataInitialValues = {
  requisicao_id: 0,
  instalador_id: 0,
  id: 0,
  pedido_id: 0,
  pedido_status_id: 0,
  match_individual: '',
  data_cadastro: '',
  data_modificacao: '',
  primeiro_match: '',
  data_expiracao: '',
  avaliacao_nota: 0,
  avaliacao_comentario: '',
  avaliacao_titulo: '',
  visualizada: '',
  situacao_garantia: '',
  cidade_id: 0,
  nome: '',
  cep: '',
  cpf_cnpj: '',
  cpf: '',
  cnpj: '',
  email: '',
  senha: '',
  celular: '',
  editado_em: '',
  criado_em: '',
  logradouro: '',
  numero: 0,
  complemento: '',
  bairro: '',
  ultimo_acesso: '',
  razao_social: '',
  foto_perfil: '',
  nota: 0,
  lat: '',
  lng: '',
  data_suspensao: null,
  status: '',
  situacao: '',
  adesao: '',
  data_adesao: '',
  homologado: '',
  disponibilidade: '',
  receber_pedidos: '',
  instalador_status_id: 0,
  confianca: '',
  isDeleted: 0,
};

export const crudDataInitialValues = {
  name: '',
  cpfCnpj: '',
  email: '',
  tel: 0,
  phone: '',
  billingCep: '',
  billingAddress: '',
  billingCity: '',
  billingState: '',
  billingDistrict: '',
  billingNumber: 0,
  billingComplement: '',
  installationCep: '',
  installationAddress: '',
  installationCity: '',
  installationState: '',
  installationDistrict: '',
  installationNumber: 0,
  installationComplement: '',
  service_date: '',
  service_shift: '',
  order_date: '',
  order_time: '',
  total_value: 0,
  installer_value: 0,
  agyx_value: 0,
  cycle: '',
  installer: '',
};

export const orderDataInitialValues = {
  id: 0,
  cidade_id: 0,
  estado_id: 0,
  nome_cliente: '',
  cpf_cnpj: '',
  descricao: '',
  data_cadastro: null,
  data_modificacao: null,
  lat: '',
  lng: '',
  logradouro: '',
  bairro: '',
  numero: '',
  complemento: '',
  tipo_servico: '',
  cep: '',
  email_cliente: '',
  celular_cliente: '',
  previsao_entrega: null,
  origem_pedido: '',
  numero_pedido: '',
  status: '',
  status_agendamento: '',
  pendente: '',
  tag_id: 0,
  pode_aceitar: '',
  data_aprovacao_pagamento: new Date(),
  ciclo_repasse_instalador: null,
  agendamento_pelo_cliente: '',
  pronto_para_match: '',
  comissao: 0,
  valor_total: 0,
  subtotal: 0,
  valor_liquido: 0,
  valor_desconto: 0,
  valor_com_desconto: 0,
  valor_repasse_instalador: 0,
  valor_repasse_agyx: 0,
  controle_salvamento: '',
  ciclo_id: null,
  conferido: 0,
  data_conferido: null,
  data_desconferido: null,
  check_fechamento: 0,
  check_envio: 0,
  check_pagamento: 0,
  numero_loja_integrada: null,
  codigo_vendedor: null,
};

export const orderItemsInitialValues = [
  {
    id: 0,
    brand: '',
    model: '',
    power: 0,
    serviceType: 0,
    itemType: 0,
    unitValue: 0,
    totalValue: 0,
    transferValue: 0,
    transferAgyx: 0,
    transferPercent: 0,
  },
];

export const orderDataByIdInitialValues = {
  id: 0,
  paymentApprovalDate: new Date(),
  installerId: 0,
  installerCellphone: '',
  installerName: '',
  orderNumber: '',
  originName: '',
  orderSavingState: null,
  registerDate: new Date(),
  lastUpdate: [],
  clientData: {
    name: '',
    cpf_cnpj: '',
    email: '',
    cellphone: '',
  },
  address: {
    installationAddressEqualBilling: false,
    billing: {
      cep: '',
      state: '',
      city: '',
      street: '',
      neighborhood: '',
      number: 0,
      complement: '',
    },
    installation: {
      cep: '',
      state: '',
      city: '',
      street: '',
      neighborhood: '',
      number: 0,
      complement: '',
    },
  },
  orderItems: [],
  requisitionId: 0,
  tag: '',
  reason: '',
};
