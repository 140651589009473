import * as React from 'react';
import { Link } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material';

import { paths } from 'src/routes/paths';

import { primary } from 'src/theme/palette';

import { useTabs } from './tabs.hook';

export interface TabsProps {
  permission?: string;
  // uf?: string;
}

interface LinkTabProps {
  label?: string;
  href?: string;
  selected?: boolean;
}

function LinkTab(props: LinkTabProps) {
  const { href, ...other } = props;
  return <Tab component={Link} to={href ?? '#'} {...other} />;
}

export default function HeaderTabs({ permission }: TabsProps) {
  const { value, handleChange, responsive } = useTabs(permission ?? '');

  const CustomTabLink = styled(LinkTab)(({ theme }) => ({
    marginTop: 8,
    color: theme.palette.common.white,
    minWidth: responsive('down', 'sm') ? '100%' : 'auto',
    '&.MuiTab-root': {
      color: 'white',
      fontWeight: theme.typography.subtitle2,
      fontSize: theme.typography.subtitle2,
      lineHeight: theme.typography.subtitle2,
      fontFamily: theme.typography.fontFamily,
    },
  }));

  const divStylesConfig = {
    height: '100%',
  };

  return (
    <div data-testid="tabs-render-test" style={divStylesConfig}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          role="navigation"
          orientation={responsive('down', 'sm') ? 'vertical' : 'horizontal'}
          TabScrollButtonProps={{
            sx: {
              color: 'white',
            }
          }}
          sx={{
            height: '100%',
            bgcolor: responsive('down', 'sm') && primary.darker,
            alignItems: responsive('down', 'sm') && 'center',
            '.css-1r5ppdd-MuiTabs-scroller': responsive('down', 'sm') && { width: '100%' },
          }}
          TabIndicatorProps={{
            sx: {
              backgroundColor: primary.light,
              height: 3,
              left: responsive('down', 'sm') && 0,
            },
          }}
        >
          <CustomTabLink label="Pedidos" href={paths.orders.root} />
          {permission !== 'Lojista' && <CustomTabLink label="Kanban" href="/kanban" />}
          {permission !== 'Lojista' && <CustomTabLink label="Usuários" href={paths.users.root} />}
          {permission !== 'Lojista' && <CustomTabLink label="Seguros" href={paths.insurance.root} />}
          {/* {permission !== 'Lojista' && <CustomTabLink label="Clientes" href="/" />} */}
          <CustomTabLink label="Instaladores" href={paths.installers.root} />
          {permission !== 'Lojista' && <CustomTabLink label="Ciclos" href={paths.cycles.root} />}
          {permission !== 'Lojista' && <CustomTabLink label="Ciclos Seguros" href={paths.insuranceCycle.root} />}
          {permission !== 'Lojista' && <CustomTabLink label="Configurações" href={paths.settings.root} />}
          {permission !== 'Lojista' && <CustomTabLink label="Notificações" href={paths.notifications.root} />}
        </Tabs>
      </Box>
    </div>
  );
}
