import { Either } from 'src/utils/either';

import { _installers } from 'src/_mock/_installer';
import { ApiError } from 'src/http/errors/api-error';
import { httpResponseHandler } from 'src/http/http-response-handler';
// import { _mockComments, getItemOptions, getChatMessagesList } from 'src/_mock/_orders-details';

import { NewSchedule } from 'src/types/order-details/new-schedule';
// import { ChatMessageItemProps } from 'src/types/order-details/chat';
import { imageInstallationType } from 'src/types/order-details/order-item';

import { HttpTypes } from '../../http';

export class OrderDetailsRepository {
  constructor(private api: HttpTypes.HttpClient) {}

  async getStateOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `localizacao/estado`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async notifyCustomer(orderId: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `orders/notifyCustomer`,
      body: {
        orderId,
      },
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getCityOptions(stateId: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `localizacao/cidade?target[]=estado_id&target_value[]=${stateId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getBrandOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `marca`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getModelOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `modelo`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getPowerOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `potencia`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getServiceTypeOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `tipo-servico`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getOrderDetailsData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido/${orderId}`,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async getOrderItems(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const url = `pedido-itens?target[]=pedido_id&target_value[]=${encodeURIComponent(orderId ?? '')}`;

    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async getTransferInfo(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: 'orders/transfer',
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getItemTypeOptions(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/orderItemTypes`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  // async getItemOptions(order_id?: string): Promise<Either<ApiError, any | undefined>> {
  //   const request: HttpTypes.HttpRequest = {
  //     method: 'get',
  //     url: `/order/details-item-options`,
  //     params: [{ key: 'order_id', value: order_id }],
  //   };

  //   const initialValues = getItemOptions();

  //   const options: HttpTypes.RequestOptions = {
  //     data: initialValues,
  //     status: 200,
  //     delay: 500,
  //   };

  //   const response = await this.api.fakeRequest(request, options);

  //   return httpResponseHandler(response);
  // }

  async getSchedules(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-agendamento/filtro?target[]=pedido_id&target_value[]=${orderId}`,
    };

    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getOrderSchedule(orderId: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/orderSchedule/${orderId}`,
    };

    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async matchSchedule(
    newSchedule: NewSchedule,
    adminId?: number
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: `pedido/match`,
      body: {
        pedido: newSchedule.order,
        agendamento: newSchedule.schedule,
        options: newSchedule.options,
        admin_id: adminId,
      },
    };

    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getInstallers(): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `instalador`,
    };
    const options: HttpTypes.RequestOptions = {
      data: _installers,
      status: 200,
      delay: 2000,
    };
    const response = await this.api.fakeRequest(request, options);
    return httpResponseHandler(response);
  }

  async getActivitiesData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/orderActivities/${orderId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getVinculedOrders(orderNumber?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/vinculedOrders/${orderNumber}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getHistoryData(orderId?: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/orderEvolution/${orderId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  // async getChatMessagesList(conversationId: string): Promise<Either<ApiError, any | undefined>> {
  //   const request: HttpTypes.HttpRequest = {
  //     method: 'get',
  //     url: `/chat-messages-list`,
  //     params: [{ key: 'conversationId', value: conversationId }],
  //   };

  //   const initialValues = getChatMessagesList(conversationId);

  //   const options: HttpTypes.RequestOptions = {
  //     data: initialValues,
  //     status: 200,
  //     delay: 2000,
  //   };

  //   const response = await this.api.fakeRequest(request, options);

  //   return httpResponseHandler(response);
  // }

  async getComments(orderId: string): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-atividade/pedido/${orderId}`,
    };

    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async sendOrdersData(data: object, id: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: `orders/updateOrderData/${id}`,
      body: data,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async createComment(data: object): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `pedido-atividade`,
      body: data,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async putOrderInWaitingStatus(
    orderNumber: number,
    dateToWait: Date,
    adminId: number,
    waitingReason?: string
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `orders/createRegisterAtOrderWaitingStatus`,
      body: { orderId: orderNumber, dateToWait, adminId, waitingReason },
    };
    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async setOrderStatus(
    orderNumber: number,
    status: string,
    date: Date,
    adminId: number,
    reason?: string
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: `orders/updateOrderStatus`,
      body: { id: orderNumber, status, date, reason, admin_id: adminId },
    };
    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async inactivateWainting(
    orderNumber: number,
    adminId: number
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'delete',
      url: `orders/removeOrderFromWaitingStatus/${orderNumber}/${adminId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async postChatMessage(body: any): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `messages/postMessage`,
      body,
    };

    const response = await this.api.request(request);

    return httpResponseHandler(response);
  }

  async getRatingData(orderId: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao/pedido/${orderId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async postFile(url: string, payload: any): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url,
      body: payload,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getOrderData(id: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao/pedido/${id}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getBillingData(id: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao-nf-agyx?target[]=pedido_requisicao_id&target_value[]=${id}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getInstallationData(
    id: number,
    requisitionId: number
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao-item-imagem?target[]=pedido_id&target[]=pedido_requisicao_id&target_value[]=${id}&target_value[]=${requisitionId}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async putInstallationImage(
    payload: imageInstallationType
  ): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: 'pedido-requisicao-item-imagem/painel',
      body: payload,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getBillingInstallerData(id: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `pedido-requisicao-nf?target[]=pedido_requisicao_id&target_value[]=${id}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async putPaymentDate(payload: { data_aprovacao_pagamento: string; id: number }) {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: 'pedido',
      body: payload,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getOrderDataById(id: number) {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `orders/orderData/${id}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getClientMessages(cellphone: string) {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `messages/clientChat/${cellphone}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async getInstallerMessages(cellphone: string) {
    const request: HttpTypes.HttpRequest = {
      method: 'get',
      url: `messages/installerChat/${cellphone}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async putOrderData(payload: any) {
    const request: HttpTypes.HttpRequest = {
      method: 'put',
      url: 'pedido',
      body: payload,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async createOrdersData(data: object): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'post',
      url: `orders/createOrderData`,
      body: data,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }

  async deleteOrder(orderNumber: number): Promise<Either<ApiError, any | undefined>> {
    const request: HttpTypes.HttpRequest = {
      method: 'delete',
      url: `orders/deleteOrder/${orderNumber}`,
    };
    const response = await this.api.request(request);
    return httpResponseHandler(response);
  }
}
