import { create } from 'zustand';
import { enqueueSnackbar } from 'notistack';

import { AxiosHttpClient } from 'src/http';
import { API_URL } from 'src/config-global';
import { OrderDetailsRepository } from 'src/repositories/orders/order-details.repository';

import { orderDataById } from 'src/types/order-details/order-item';

import { orderDataByIdInitialValues } from './initialValues';

type States = {
  isLoading: boolean;
  detailedOrderId?: number;
  quickViewDrawerState: boolean;
  orderData: orderDataById;
  orderStatus: {
    status: string;
    date: Date;
    reason?: string;
  };
  actionDialogTitle: string;
  actionDialogStatus: string;
  transferInfo: any;
  isCreating: boolean;
};

type Actions = {
  setDetailedOrderId: (value: number | undefined) => void;
  setQuickViewDrawerState: (value: boolean) => void;
  setIsCreating: (value: boolean) => void;
  resetOrderData: () => void;
  getOrderDataById: (id: number) => Promise<void>;
  putOrderInWaitingStatus: (
    orderNumber: number,
    date: Date,
    adminId: number,
    reason?: string
  ) => Promise<void>;
  inactivateWaiting: (orderNumber: number, adminId: number) => Promise<void>;
  deleteOrder: (orderNumber: number) => Promise<void>;
  addOrderItems: (data: any) => void;
  editOrderItem: (data: any, id: number) => void;
  deleteOrderItem: (id: number) => void;
  setOrderStatus: (
    orderNumber: number,
    status: string,
    date: Date,
    adminId: number,
    reason?: string
  ) => Promise<void>;
  getTransferConfigInfo: () => void;
  setActionDialogTitle: (value: string) => void;
  setActionDialogStatus: (value: string) => void;
};

type OrderDetails = Actions & States;

const initialValues: States = {
  isLoading: false,
  detailedOrderId: undefined,
  quickViewDrawerState: false,
  orderData: orderDataByIdInitialValues,
  orderStatus: {
    status: 'Em Andamento',
    date: new Date(),
  },
  transferInfo: undefined,
  isCreating: false,
  actionDialogTitle: '',
  actionDialogStatus: '',
};

const newApi = new AxiosHttpClient(API_URL);
const newRepository = new OrderDetailsRepository(newApi);

export const useOrderDetails = create<OrderDetails>()((set: any, get: any) => ({
  ...initialValues,

  setDetailedOrderId(value: number | undefined) {
    set({ detailedOrderId: value });
  },

  setIsCreating(value: boolean) {
    set({ isCreating: value });
  },

  resetOrderData() {
    set({ orderData: orderDataByIdInitialValues });
  },

  setQuickViewDrawerState(value: boolean) {
    set({ quickViewDrawerState: value });
  },

  setActionDialogTitle: async (value: string) => {
    set({ actionDialogTitle: value });
  },

  setActionDialogStatus: async (value: string) => {
    set({ actionDialogStatus: value });
  },

  getOrderDataById: async (id: number) => {
    set({ isLoading: true });
    const response = await newRepository.getOrderDataById(id);
    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
    set({ isLoading: false });
    set({
      orderData: response?.value,
      orderStatus: {
        status: response?.value?.orderWaitingData[0] ? 'EM ESPERA' : response?.value?.tag,
        date:
          response?.value?.orderWaitingData[0]?.data_espera ??
          (response?.value?.lastUpdate[0]?.data_cadastro || response?.value?.registerDate),
        reason: response?.value?.orderWaitingData[0]?.motivo_espera,
      },
    });
  },

  getTransferConfigInfo: async () => {
    set({ isLoading: true });
    const response = await newRepository.getTransferInfo();
    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
    set({ isLoading: false, transferInfo: response.value });
  },

  setOrderStatus: async (orderNumber, status, date, adminId, reason) => {
    set({ isLoading: true });
    const response = await newRepository.setOrderStatus(orderNumber, status, date, adminId, reason);
    if (response.isLeft()) {
      throw new Error(response.value.message);
    }
    set({ isLoading: false, orderStatus: { status, date, reason } });
  },

  putOrderInWaitingStatus: async (orderNumber, dateToWait, adminId, waitingReason) => {
    set({ isLoading: true });
    const response = await newRepository.putOrderInWaitingStatus(
      orderNumber,
      new Date(dateToWait),
      adminId,
      waitingReason
    );
    if (response.isLeft()) {
      throw new Error(response.value.message);
    }
    set({ isLoading: false, orderStatus: { dateToWait, waitingReason } });
  },

  inactivateWaiting: async (orderNumber: number, adminId: number) => {
    set({ isLoading: true });
    const response = await newRepository.inactivateWainting(orderNumber, adminId);
    if (response.isLeft()) {
      set({ isLoading: false });
      throw new Error(response.value.message);
    }
    set({ isLoading: false, orderStatus: {} });
  },

  addOrderItems: (data) => {
    set((state: any) => ({
      orderData: {
        ...state.orderData,
        orderItems: [...state.orderData.orderItems, data],
      },
    }));
  },

  editOrderItem: (data, id) => {
    set((state: any) => ({
      orderData: {
        ...state.orderData,
        orderItems: state.orderData.orderItems.map((item: any) =>
          item.id === id ? { ...item, ...data } : item
        ),
      },
    }));
  },

  deleteOrderItem: (id) => {
    set((state: any) => ({
      orderData: {
        ...state.orderData,
        orderItems: state.orderData.orderItems.filter((item: any) => item.id !== id),
      },
    }));
  },
  deleteOrder: async (orderNumber: number) => {
    set({ isLoading: true });
    const response = await newRepository.deleteOrder(orderNumber);
    if (response.isLeft()) {
      set({ isLoading: false });
      enqueueSnackbar({
        message: 'Houve um erro, tente novamente mais tarde!',
        variant: 'error',
      });
      throw new Error(response.value.message);
    }
    enqueueSnackbar({
      message: 'Pedido deletado com sucesso!',
      variant: 'success',
    });
    set({ isLoading: false });
  },
}));
